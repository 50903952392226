import {
  Breadcrumbs,
  FiltersBarV2,
  Row,
  Spacer,
  Text,
  Title
} from '@smu-chile/pkg-unimarc-components'
import { clickPlp } from '../../shared/utils'
import Link from 'next/link'
import { GeneralObjectCategories } from './index'
import { useRouter } from 'next/router'
import useScrollListener from '@smu-chile/pkg-unimarc-components/stories/organisms/HeaderPrototype/helpers/useScrollListener'
import { useRef } from 'react'
import styles from './CustomHeader.module.css'
import { useCustomHeader } from './helpers/useCustomHeader'
import { useMobile } from '@smu-chile/pkg-unimarc-hooks'

interface CustomHeaderProps {
  breadcrumbData: GeneralObjectCategories[]
  title: string
  totalQuantity: string | number
  isMobile: boolean
  selectedRadio: string
  hasFacets?: boolean
  onClickFilterModal: () => void
  handleOpenFacetModal?: () => void
}
export const CustomHeader = ({
  breadcrumbData,
  isMobile,
  selectedRadio,
  title,
  totalQuantity,
  hasFacets,
  onClickFilterModal,
  handleOpenFacetModal
}: CustomHeaderProps) => {
  const router = useRouter()
  const primary = useRef(null)
  const scroll = useScrollListener()
  const fontSizeQuantity = isMobile ? 'sm' : 'base'
  const { innerWidth } = useMobile()

  useCustomHeader({ isMobile, innerWidth, scroll, primary })

  if (isMobile && !router?.query?.brand) {
    return (
      <Row
        customClassName={styles.customHeader}
        ref={primary}
      >
        <FiltersBarV2
          filterQuantity={hasFacets}
          onClickShowFilter={handleOpenFacetModal}
          onClickShowOrder={onClickFilterModal}
          orderByName={selectedRadio}
          totalQuantity={totalQuantity}
        />
      </Row>
    )
  }

  return (
    <>
      <Row>
        <Breadcrumbs
          afterClick={(label) => {
            clickPlp(label, 'breadcrumb')
          }}
          linkWrapper={Link}
          links={breadcrumbData}
        />
      </Row>
      <Spacer.Horizontal size={12} />
      <Row alignItems='baseline'>
        <Title
          fontWeight='semibold'
          headingLevel='h1'
          size='small'
        >
          {title}
        </Title>
        <Spacer.Vertical size={8} />
        <Text
          color='gray'
          fontSize={fontSizeQuantity}
        >
          ({totalQuantity} resultados)
        </Text>
      </Row>
    </>
  )
}
