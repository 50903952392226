import { NextRouter } from 'next/router'
import { useState } from 'react'
import { handleOrderBy, returnMatchedKeyName } from 'shared/helpers'

export interface UseOrderData {
  showModal: boolean
  onApplyFilterModal: () => void
  onClickFilter: (orderByName: string) => void
  onClickFilterModal: () => void
  onClickRadioOrderByOption: (radioValue: string) => void
  selectedRadio: string
  disabledButton?: boolean
}

export const useOrder = (router: NextRouter): UseOrderData => {
  const orderByRadioName = returnMatchedKeyName(
    router?.query?.orderBy?.toString()
  )

  const [showModal, setShowModal] = useState(false)
  const [disabledButton, setDisabledButton] = useState(false)

  const [selectedRadio, setSelectedRadio] = useState(
    orderByRadioName || 'Recomendados'
  )

  const onClickFilter = handleOrderBy({ router })

  const onClickFilterModal = (): void => {
    setShowModal(!showModal)
    setDisabledButton(true)
  }

  const onApplyFilterModal = (): void => {
    onClickFilterModal()
    onClickFilter(selectedRadio)
  }

  const onClickRadioOrderByOption = (radioValue: string): void => {
    setSelectedRadio(radioValue)
    setDisabledButton(false)
  }

  return {
    showModal,
    onApplyFilterModal,
    onClickFilter,
    onClickFilterModal,
    onClickRadioOrderByOption,
    selectedRadio,
    disabledButton
  }
}
