const isProd = process.env.NEXT_PUBLIC_CONTENTFUL_ENVIRONMENT === 'master'

export const ERROR_400_IMG = {
  0: isProd ? '6jS1S1EgPDysU79DKeRvWS' : '2Ug1dQpSY4TCzPZeTxdzYN'
}

export const ERROR_404_IMG = {
  0: isProd ? '1mCxqNwobrC0dofjXgB8iW' : '42LyQUf2uc9m0j74mmEnt5'
}

export const ERROR_500_IMG = {
  0: isProd ? '7crW4vvsA502bswpmHoHo6' : '7LNBJSGlaOutsjEUUfIrTn'
}

export const ERROR_500_IMG_V2 = {
  0: isProd ? '7crW4vvsA502bswpmHoHo6' : '1PLoGO805vZp7X7jD8Blis'
}

export const ERROR_500_IMG_BREAD = {
  0: isProd ? '3Lwr0nMBxQuGHfYDaBUdzE' : '3A6T2KqcKPXPltHDszreaC'
}
