import { useState } from 'react'

export interface UseFacetsData {
  showFacets: boolean
  handleOpenFacetModal: () => void
}

export const useFacets = (): UseFacetsData => {
  const [showFacets, setShowFacets] = useState(false)

  const handleOpenFacetModal = (): void => {
    setShowFacets(!showFacets)
  }

  return {
    showFacets,
    handleOpenFacetModal
  }
}
