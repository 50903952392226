import Link from 'next/link'
import { useRouter } from 'next/router'
import React, { useEffect, useRef, useState } from 'react'
import Image from 'next/future/image'
import {
  ButtonStatus,
  IconProps,
  MemoShelf,
  Modal,
  QuantityButtonProps,
  Row
} from '@smu-chile/pkg-unimarc-components'
import {
  isValidArrayWithData,
  postClickSponsoredProducts,
  productClickTag,
  shelvesImpression,
  useEvents,
  useProductsCoupon,
  useUpdateFromShelf
} from '@smu-chile/pkg-unimarc-hooks'
import { Product } from '@smu-chile/pkg-unimarc-hooks/shared/interfaces/IProducts'
import { AvailableProduct } from '@smu-chile/pkg-unimarc-hooks/shared/interfaces/IIntelligenceSearch'
import { formatProductUrl } from '@smu-chile/pkg-unimarc-hooks/shared/helpers/formatProductUrl'
import { getDataPromotion } from '../../shared/helpers'
import { LoadingInfinityScroll } from '../ProductListPage/helpers'
import { IProductInList } from '@smu-chile/pkg-unimarc-components/shared/interfaces/ILists'
import { ICoupon } from '@smu-chile/pkg-unimarc-hooks/shared/interfaces/ICoupon'
import { ModalProps } from '@smu-chile/pkg-unimarc-components/stories/molecules/Modal/helpers'
import { SimilarsModal } from 'components/SimilarsModal/SimilarsModal'
import { statusPromotionsV2 } from '@smu-chile/pkg-unimarc-components/helpers/promotionsOfShelvesV2'
import styles from './Shelves.module.css'
import { ProductDetailModalWrapper } from 'components/ProductDetailModalWrapper'

export interface Data extends Product, AvailableProduct {
  buttonStatus?: ButtonStatus
  isDisabled?: boolean
  isLoading?: boolean
  quantity?: string | number
}
export interface ShelvesProps {
  clusterName?: string
  continuosFetching?: boolean
  dataPromotions?: unknown[]
  isMobile: boolean
  isSimilarsModal?: boolean
  orderForm?: string
  orientation?: 'vertical' | 'horizontal'
  products: AvailableProduct[] | Product[]
  reference?: string
  quantityButtonProps?: Omit<QuantityButtonProps, 'value'>
  handleOnClick?: (props?: { itemId: string }) => void
  handleOpenMyListsModal?: (props: IProductInList) => void
  setCountLimit?: () => void
}

export interface CouponModalProps extends Partial<ICoupon> {
  couponLabel?: string
  couponStatus?: string
  couponLoading?: boolean
  onActiveCoupon?: () => void
}
interface ProductDetailModalProps {
  slug: string
  couponModal?: CouponModalProps
  ad?: IAdsProps
}

interface IAdsProps {
  adId: string
  productId: string
  productName: string
  imageUrl: string
  searchedText: string | null
  searchedCategory: string | null
}
interface ProductSimilarsProps {
  slug: string
  couponModal?: CouponModalProps
}

export const Shelves = ({
  clusterName,
  continuosFetching,
  dataPromotions,
  isMobile,
  isSimilarsModal,
  orientation = 'vertical',
  products,
  reference,
  handleOpenMyListsModal,
  setCountLimit
}: ShelvesProps): React.ReactElement => {
  const site = 'Unimarc'
  const router = useRouter()
  const allowedPages =
    router?.asPath.includes('category') ||
    router?.asPath.includes('ofertas') ||
    router?.asPath.includes('search')
  const [isOrderFormLoading, setIsOrderFormLoading] = useState<boolean>(false)
  const [isOpenModalDetail, setIsOpenModalDetail] = useState(false)
  const [isOpenSimilarsModal, setIsOpenSimilarsModal] = useState(false)
  const [currentPath, setCurrentPath] = useState<string>('')
  const [productSlug, setProductSlug] = useState<string>('')
  const [productCouponModal, setProductCouponModal] = useState({})
  const shelvesInfoRef = useRef([])
  const promotionTagInfo =
    typeof window !== 'undefined' &&
    JSON.parse(sessionStorage.getItem(window?.location?.pathname))

  const {
    shelvesData: productsWithoutCoupons,
    editTempOrderForm,
    handleAdd,
    handleChange,
    handleOnClick,
    handleRemove
  } = useUpdateFromShelf({
    clusterName,
    isOrderFormLoading,
    products,
    promotionTagInfo,
    reference
  })

  const changeToModalUrl = (slug?: string) => {
    const { asPath } = router
    setCurrentPath(asPath)
    const formattedSlug = formatProductUrl(slug)
    window.history.replaceState({}, '', formattedSlug)
  }

  const changeToPLPUrl = () => {
    window.history.replaceState({}, '', currentPath)
  }

  const onClickProductDetail = ({
    ad,
    slug,
    couponModal
  }: ProductDetailModalProps) => {
    setProductCouponModal(couponModal)
    setProductSlug(slug)
    setIsOpenModalDetail((prevState) => {
      return !prevState
    })
    if (Object?.entries(ad).length > 0) {
      postClickSponsoredProducts({ ...ad })
    }
    changeToModalUrl(slug)
  }
  const onClickProductSimilars = ({
    slug,
    couponModal
  }: ProductSimilarsProps) => {
    setProductCouponModal(couponModal)
    setProductSlug(slug)
    setIsOpenModalDetail((prevState) => {
      return !prevState
    })
  }

  useEffect(() => {
    if (isOpenModalDetail) {
      document.body.style.overflow = 'hidden'
    } else {
      document.body.style.overflow = 'unset'
    }
  }, [isOpenModalDetail])

  useEffect(() => {
    if (isOpenSimilarsModal) {
      document.body.style.overflow = 'hidden'
    } else {
      document.body.style.overflow = 'unset'
    }
  }, [isOpenSimilarsModal])

  const handleModalClose = () => {
    changeToPLPUrl()
    setIsOpenModalDetail(false)
    setIsOpenSimilarsModal(false)
  }

  const { products: shelvesData } = useProductsCoupon(productsWithoutCoupons)

  const modalProps: ModalProps = isMobile
    ? {
        styleProps: {
          borderRadius: '0.75rem 0.75rem 0 0',
          padding: '16px 0 0 0'
        },
        iconsProps: {
          customCloseIcon: 'Close' as IconProps['name'],
          customClassNameCloseIcon: styles.modalCloseIcon,
          sizeIconClose: 20,
          iconPadding: '0 16px 10px 16px'
        },
        dragProps: {
          dragRatio: 0.2,
          draggButton: false
        },
        modalConfigsProps: {
          isWindowBlocked: true,
          isOpen: isOpenModalDetail || isOpenSimilarsModal,
          toggle: handleModalClose,
          toggleOutside: handleModalClose,
          openAnimation: true,
          maxWidth: 'max-content',
          fullScreenSticky: true,
          isAutoHeight: true
        }
      }
    : {
        styleProps: {
          height: 'max-content',
          padding: '0'
        },
        iconsProps: {
          iconActionHover: true,
          iconPadding: '20px',
          sizeIconClose: isOpenSimilarsModal ? 20 : 16
        },
        modalConfigsProps: {
          isOpen: isOpenModalDetail || isOpenSimilarsModal,
          maxHeight: '550px',
          maxWidth: '837px',
          toggle: handleModalClose,
          toggleOutside: handleModalClose
        }
      }

  const isVertical: boolean = orientation === 'vertical'

  //When the event is called this function there go to read all items that will update and set a new loading state for each item
  useEvents({
    eventType: 'loadingOrderForm',
    callBack: ({ detail: { loading, items } }) => {
      setIsOrderFormLoading(loading)
      editTempOrderForm(items, loading)
    }
  })

  useEffect(() => {
    if (shelvesInfoRef.current.length === shelvesData?.length) {
      shelvesImpression({
        clusterName,
        reference,
        shelves: shelvesInfoRef.current,
        site
      })
    }
  }, [shelvesInfoRef.current, shelvesData])

  if (isOpenSimilarsModal) {
    return (
      <Row
        customClassName={isVertical ? '' : styles.scrollStyle}
        isWrap={isVertical}
        overflow='auto'
      >
        <Modal {...modalProps}>
          <SimilarsModal
            coupon={productCouponModal}
            isMobile={isMobile}
            productSlug={productSlug}
            setIsOpenSimilarsModal={handleModalClose}
          />
        </Modal>
      </Row>
    )
  }

  return (
    <Row
      customClassName={isVertical ? '' : styles.scrollStyle}
      isWrap={isVertical}
      overflow='auto'
    >
      <ProductDetailModalWrapper
        handleModalClose={handleModalClose}
        isMobile={isMobile}
        modalProps={modalProps}
        productCouponModal={productCouponModal}
        productSlug={productSlug}
      />
      {isValidArrayWithData(shelvesData) &&
        shelvesData.map((product, index) => {
          const {
            isPromotion,
            isPaymentsMethods,
            price,
            pricePromotion,
            ppum,
            offer,
            pricePaymentsMethods
          } = statusPromotionsV2(product)
          const promotion = getDataPromotion(
            dataPromotions,
            product?.promotion?.['name']
          )

          const urlName = formatProductUrl(product?.['slug'])
          const lastChild = index === shelvesData?.length - 1
          const couponModal: CouponModalProps = product?.coupon
            ? {
                ...product?.coupon,
                couponLabel: product?.couponLabel,
                couponStatus: product?.couponStatus,
                onActiveCoupon: product?.onActiveCoupon
              }
            : {}
          const format =
            product?.measurementUnit?.toLowerCase() === 'kg'
              ? product?.['netContentLevelSmall']
              : product?.['netContent']

          const typePromotion = {
            '': 0,
            price: 1,
            percentage: 2,
            mxn: 3,
            mx$: 4,
            'm%n': 5
          }

          return (
            <React.Fragment key={product?.itemId}>
              <MemoShelf
                {...{ ...product, format }}
                altText={product.name}
                catchShelfRef={(ref) => {
                  if (!ref) return

                  shelvesInfoRef.current = [
                    ...shelvesInfoRef.current,
                    { ref, product, position: index + 1 }
                  ]
                }}
                containerWidth='25%'
                couponChannel={product?.coupon?.entry?.fields?.channel}
                handleOnClick={handleOnClick}
                handleOpenMyListsModal={handleOpenMyListsModal}
                img={product?.item?.images?.[0] || product?.images?.[0]}
                inOffer={product?.sellers?.[0]?.inOffer}
                isMobile={isMobile}
                isMporcentageNPromo={
                  product?.priceDetail?.promotionType === 'm%n'
                }
                isMxNPromotion={product?.priceDetail?.promotionType === 'mxn'}
                isPaymentsMethods={isPaymentsMethods}
                isPromotion={isPromotion}
                isSimilarsModal={isSimilarsModal}
                itemRequired={product?.priceDetail?.itemsRequiredForPromotion}
                likeListButtons={false}
                linkWrapper={Link}
                listPrice={product?.priceDetail?.listPrice}
                membershipData={product?.priceDetail?.membership} //TODO: to review
                modalProduct={allowedPages}
                nextImage={Image}
                offer={offer}
                onClickProduct={() => {
                  productClickTag({ product, site, reference, clusterName })
                }}
                onClickProductDetail={() => {
                  onClickProductDetail({
                    ad: product?.ad || {},
                    couponModal,
                    slug: product?.['slug']
                  })
                }}
                onClickProductSimilars={() => {
                  onClickProductSimilars({
                    slug: product?.['slug'],
                    couponModal
                  })
                }}
                orientation={orientation}
                ppum={`(${ppum})`}
                ppumListPrice={product?.priceDetail?.ppumListPrice}
                price={price}
                priceDetail={product?.priceDetail}
                pricePaymentsMethods={pricePaymentsMethods}
                pricePromotion={pricePromotion}
                promotion={promotion}
                quantityButtonProps={{
                  ...product?.['quantityButtonProps'],
                  handleAdd,
                  handleRemove,
                  handleChange
                }}
                quantityButtonStyles={{
                  fontSize: 'md',
                  maxHeight: '30px',
                  maxWidth: isVertical ? '164px' : '175px',
                  sizeCircle: 22
                }}
                router={router}
                saving={product?.priceDetail?.promotionMessage}
                setIsOpenSimilarsModal={setIsOpenSimilarsModal}
                showAddToListButton={true}
                title={product?.['item']?.name || product?.name}
                typePromotion={
                  typePromotion[product?.priceDetail?.promotionType]
                }
                url={urlName}
              />
              {lastChild && (
                <LoadingInfinityScroll
                  callback={setCountLimit}
                  fetchMore={continuosFetching}
                  render={<div />}
                />
              )}
            </React.Fragment>
          )
        })}
    </Row>
  )
}
