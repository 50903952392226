import { useEffect } from 'react'
import { useRouter } from 'next/router'
import {
  BigScreen,
  getGlobalStyle,
  SmallScreen
} from '@smu-chile/pkg-unimarc-components/helpers'
import {
  Button,
  Column,
  Container,
  FacetFilters,
  MobileModalWrapper,
  Row,
  SelectByOptions,
  Spacer
} from '@smu-chile/pkg-unimarc-components'
import {
  BodyPageProducts,
  BodyPageProductsProps,
  LegalTerms,
  MonetiseBanner
} from 'components'
import dynamic from 'next/dynamic'
import { orderByOptions } from 'shared/helpers'
import { useAndesMLClickEvent } from '@smu-chile/pkg-unimarc-hooks'

const OrderByRadioButtonList = dynamic<SelectByOptions>(
  import('@smu-chile/pkg-unimarc-components').then((mod) => {
    return mod.OrderByRadioButtonList
  })
)
export type SearchBodyProductProps = JSX.IntrinsicAttributes &
  BodyPageProductsProps

export const Main = ({ children }: { children: React.ReactNode }) => {
  return (
    <Container
      alignItems='start'
      backgroundColor={getGlobalStyle('--color-background-white')}
      justifyContent='center'
      tagName='main'
    >
      {children}
    </Container>
  )
}

export const SearchBodyProduct = (
  dataOfBodyPageProducts: SearchBodyProductProps
) => {
  const router = useRouter()
  const { handleClickOnAndesMLBanner } = useAndesMLClickEvent()
  useEffect(() => {
    if (!router.query?.orderBy) {
      dataOfBodyPageProducts.onClickRadioOrderByOption('Recomendados')
    }
  }, [router.query?.orderBy])

  return (
    <Main>
      <BigScreen>
        <Column alignItems='center'>
          {!dataOfBodyPageProducts?.isLoadingBannerAndes &&
            (dataOfBodyPageProducts?.bannerImages ||
              dataOfBodyPageProducts?.bannerAndesMl) && (
              <MonetiseBanner
                data={dataOfBodyPageProducts?.searchBannerData}
                handleClickOnAndesMLBanner={handleClickOnAndesMLBanner}
                imageAndes={dataOfBodyPageProducts.bannerAndesMl.imageUrl}
                images={dataOfBodyPageProducts?.bannerImages}
                key={dataOfBodyPageProducts?.query}
                padding='32px 0 0 0'
                urlLink={dataOfBodyPageProducts?.urlLinkOffer}
              />
            )}
          {!dataOfBodyPageProducts?.bannerImages && (
            <Spacer.Horizontal size={24} />
          )}
          <Container
            backgroundColor={getGlobalStyle('--color-background-white')}
            isWrap
            maxWidth={getGlobalStyle('--width-max-desktop')}
            minHeight='90vh'
            padding='10px 0px'
            width='1120px'
          >
            <Column alignItems='center'>
              <BodyPageProducts {...dataOfBodyPageProducts} />
            </Column>
            <LegalTerms
              paragraph={dataOfBodyPageProducts?.contentLegalParagraph}
              title={dataOfBodyPageProducts?.contentLegalTitle}
            />
          </Container>
        </Column>
      </BigScreen>
      <SmallScreen>
        <Column>
          {dataOfBodyPageProducts?.showModal && (
            <MobileModalWrapper
              blockId='orderBy'
              body={
                <OrderByRadioButtonList
                  onClick={dataOfBodyPageProducts.onClickRadioOrderByOption}
                  options={orderByOptions}
                  selected={dataOfBodyPageProducts.selectedRadio}
                />
              }
              headerTitle='Ordena tus productos'
              iconSize={20}
              isEnableButton={!dataOfBodyPageProducts.disabledButton}
              modalConfigsProps={{
                isOpen: dataOfBodyPageProducts.showModal,
                toggle: dataOfBodyPageProducts.onClickFilterModal,
                toggleOutside: dataOfBodyPageProducts.onClickFilterModal
              }}
              onApply={dataOfBodyPageProducts.onApplyFilterModal}
              onClose={dataOfBodyPageProducts.onClickFilterModal}
            />
          )}
          {dataOfBodyPageProducts?.showFacets && (
            <MobileModalWrapper
              blockId='facets'
              body={
                <FacetFilters
                  {...(dataOfBodyPageProducts?.filterData || {})}
                  isMobile={dataOfBodyPageProducts.isMobile}
                />
              }
              headerLeftElement={
                dataOfBodyPageProducts?.filterData?.hasFilter && (
                  <Column
                    flexGrow={0}
                    width='auto'
                  >
                    <Button
                      color={getGlobalStyle('--color-primary-light-red')}
                      fontSize='12px'
                      fontWeight='600'
                      height='initial'
                      label='Limpiar'
                      minWidth='auto'
                      onClick={
                        dataOfBodyPageProducts.filterData.handleClearOnClick
                      }
                      type='plain'
                    />
                  </Column>
                )
              }
              headerTitle='Selecciona tus filtros'
              iconSize={20}
              isEnableButton={dataOfBodyPageProducts.filterData.enableButton}
              modalConfigsProps={{
                isOpen: dataOfBodyPageProducts.showFacets,
                toggle: dataOfBodyPageProducts.handleOpenFacetModal,
                toggleOutside: dataOfBodyPageProducts.handleOpenFacetModal
              }}
              onApply={() => {
                return dataOfBodyPageProducts.filterData.handleApplyOnClick(
                  dataOfBodyPageProducts.handleOpenFacetModal
                )
              }}
              onClose={dataOfBodyPageProducts.handleOpenFacetModal}
            />
          )}
          <Row>
            <Column backgroundColor={getGlobalStyle('--color-base-white')}>
              <BodyPageProducts {...dataOfBodyPageProducts} />
              <LegalTerms
                paragraph={dataOfBodyPageProducts?.contentLegalParagraph}
                title={dataOfBodyPageProducts?.contentLegalTitle}
              />
            </Column>
          </Row>
        </Column>
      </SmallScreen>
    </Main>
  )
}
