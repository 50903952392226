import Script from 'next/script'
import { useState } from 'react'
import NextNProgress from 'nextjs-progressbar'
import '@smu-chile/pkg-unimarc-components/assets/styles/globals.css'
import '@smu-chile/pkg-unimarc-components/assets/styles/tokens.css'
import { useMobile } from '@smu-chile/pkg-unimarc-hooks'
import {
  Hydrate,
  QueryClient,
  QueryClientProvider
} from '@smu-chile/pkg-unimarc-hooks/reactQuery'
import { ReactQueryDevtools } from '@smu-chile/pkg-unimarc-hooks/reactQuery/devtools'
import { Layout } from 'components/Layout'
import ErrorBoundary from 'components/ErrorBoundary'
import { AppPropsWithLayout } from 'shared/interfaces/Layout'
import { checkDevice, checkMobileUserAgent } from 'shared/utils/isMobile'
import '../styles/globals.css'
import { GoogleReCaptchaProvider } from 'react-google-recaptcha-v3'

const MyApp = ({
  Component,
  pageProps,
  isDevice,
  isInvalidUrlForReCaptcha
}: AppPropsWithLayout): React.ReactElement => {
  const { innerWidth } = useMobile()
  const [queryClient] = useState(() => {
    return new QueryClient()
  })
  const isMobile = checkDevice({ isMobile: isDevice, innerWidth })

  const getLayout =
    Component.getLayout ??
    ((page) => {
      return (
        <Layout isMobile={isMobile}>
          <ErrorBoundary>{page}</ErrorBoundary>
        </Layout>
      )
    })

  return (
    <>
      <Script
        src={`https://maps.googleapis.com/maps/api/js?key=${process.env.NEXT_PUBLIC_GOOGLE_MAPS_KEY}&libraries=places,geometry`}
        strategy='beforeInteractive'
      />
      <Script
        dangerouslySetInnerHTML={{
          __html: `
          (function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':
          new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],
          j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src=
          'https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);
          })(window,document,'script','dataLayer', 'GTM-TSJPRBR');
        `
        }}
        strategy='afterInteractive'
      />
      <Script
        dangerouslySetInnerHTML={{
          __html: `
            (function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':
            new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],
            j=d.createElement(s),dl=l!='dataLayerAndesMl'?'&l='+l:'';j.async=true;j.src=
            'https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);
            })(window,document,'script','dataLayerAndesMl','GTM-5W2CWFWD');
        `
        }}
        strategy='afterInteractive'
      />
      <NextNProgress
        color='#590700'
        options={{ showSpinner: false }}
      />
      {!isInvalidUrlForReCaptcha ? (
        <GoogleReCaptchaProvider
          language='es-419'
          reCaptchaKey={process.env.NEXT_PUBLIC_RECAPTCHA_V3_KEY}
          scriptProps={{
            async: false,
            defer: true
          }}
          useEnterprise={true}
        >
          <QueryClientProvider client={queryClient}>
            {getLayout(
              <Hydrate state={pageProps?.dehydratedState}>
                <Component {...{ ...pageProps, isMobile }} />
              </Hydrate>
            )}
            <ReactQueryDevtools />
          </QueryClientProvider>
        </GoogleReCaptchaProvider>
      ) : (
        <QueryClientProvider client={queryClient}>
          {getLayout(
            <Hydrate state={pageProps?.dehydratedState}>
              <Component {...{ ...pageProps, isMobile }} />
            </Hydrate>
          )}
          <ReactQueryDevtools />
        </QueryClientProvider>
      )}
    </>
  )
}

MyApp.getInitialProps = ({ ctx }) => {
  const isInvalidUrlForReCaptcha = ctx?.req?.url?.includes('source=APP')
  return {
    isInvalidUrlForReCaptcha,
    isDevice: checkMobileUserAgent(ctx?.req?.headers?.['user-agent'])
  }
}

export default MyApp
