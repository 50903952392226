import { Modal } from '@smu-chile/pkg-unimarc-components'
import { ModalProps } from '@smu-chile/pkg-unimarc-components/stories/molecules/Modal/helpers'
import { CouponModalProps } from 'components'
import { ProductDetailModal } from 'components/ProductDetailModal/ProductDetailModal/ProductDetailModal'
import router from 'next/router'

export const ProductDetailModalWrapper = ({
  modalProps,
  isMobile,
  productCouponModal,
  productSlug,
  handleModalClose
}: {
  modalProps: ModalProps
  isMobile: boolean
  productCouponModal: CouponModalProps
  productSlug: string
  handleModalClose: () => void
}) => {
  return (
    <Modal {...modalProps}>
      <ProductDetailModal
        coupon={productCouponModal}
        handleModalClose={handleModalClose}
        isMobile={isMobile}
        productSlug={productSlug}
        text={router?.query?.q}
      />
    </Modal>
  )
}
