import { useRouter } from 'next/router'
import {
  Text,
  Column,
  Container,
  Row,
  Spacer,
  Picture,
  TextProps
} from '@smu-chile/pkg-unimarc-components'
import { getBemId } from '@smu-chile/pkg-unimarc-components/helpers'
import { useMobile } from '@smu-chile/pkg-unimarc-hooks'
import { MEMBERSHIP_LEVELS } from 'shared/utils/constanst'
import { formatTextWithLineBreaks } from '@smu-chile/pkg-unimarc-components/shared/helpers'
import { TFontSize } from '@smu-chile/pkg-unimarc-components/shared/interfaces/TFontSize'

interface IModalMembershipTypeContentConfig {
  imageWidth: string
  imageHeight: string
  maxWidthRow: string
  modalMessage: string
  titleSize: TFontSize
  titleWeight: TextProps['fontWeight']
  modalMessageSize: TFontSize
  modalMessageBoldyTextWeight: TextProps['fontWeight']
  modalMessageBoldyTextSize: TFontSize
  diamondMemberMessage: string
}
interface IModalMembershipTypeContent {
  mobile: IModalMembershipTypeContentConfig
  desktop: IModalMembershipTypeContentConfig
}

const MODAL_MEMBERSHIP_TYPE_CONTENT: IModalMembershipTypeContent = {
  mobile: {
    imageWidth: '343px',
    imageHeight: '258px',
    maxWidthRow: '343px',
    modalMessage:
      'Disfruta los beneficios y descuentos que el Club\ntiene para ti. Recuerda que todas tus compras te\nayudan a seguir subiendo de nivel.',
    modalMessageSize: 'md',
    modalMessageBoldyTextWeight: 'bold',
    modalMessageBoldyTextSize: 'md',
    titleSize: 'xl',
    titleWeight: 'semibold',
    diamondMemberMessage:
      'Disfruta los beneficios y descuentos que el Club tiene para ti.'
  },
  desktop: {
    imageWidth: '240px',
    imageHeight: '182px',
    maxWidthRow: '240px',
    modalMessage:
      'Disfruta los beneficios y descuentos que el Club tiene para ti. Recuerda que\ntodas tus compras te ayudan a seguir subiendo de nivel.',
    modalMessageSize: 'base',
    modalMessageBoldyTextWeight: 'medium',
    modalMessageBoldyTextSize: 'lg',
    titleSize: '3xl',
    titleWeight: 'medium',
    diamondMemberMessage:
      'Disfruta los beneficios y descuentos que el Club tiene para ti.'
  }
}

export const ModalMembershipTypeContent = ({
  membershipLevel
}: {
  membershipLevel: string
}): React.ReactElement => {
  const router = useRouter()
  const blockId = 'modalMembershipTypeContent'
  const { innerWidth } = useMobile()
  const isMobile = innerWidth < 1280
  const configValue = isMobile
    ? MODAL_MEMBERSHIP_TYPE_CONTENT.mobile
    : MODAL_MEMBERSHIP_TYPE_CONTENT.desktop
  const modalMessage =
    membershipLevel === 'diamante'
      ? configValue.diamondMemberMessage
      : configValue.modalMessage

  return (
    <Container
      maxWidth='100%'
      position='relative'
      tagName='section'
    >
      <Column alignItems='center'>
        {/* welcome message */}
        <Row
          justifyContent='center'
          tagName='article'
        >
          <Text
            fontSize={configValue.titleSize}
            fontWeight={configValue.titleWeight}
          >
            ¡Felicidades!
          </Text>
        </Row>
        <Spacer.Horizontal customSize={isMobile ? 26 : 14} />
        {/* image membership type */}
        <Row
          maxWidth={configValue.maxWidthRow}
          tagName='article'
        >
          <Picture
            alt={MEMBERSHIP_LEVELS[membershipLevel]?.alt}
            height={configValue.imageHeight}
            src={`${router.basePath}/${MEMBERSHIP_LEVELS[membershipLevel]?.url}`}
            title={MEMBERSHIP_LEVELS[membershipLevel]?.alt}
            width={configValue.imageWidth}
          />
        </Row>
        <Spacer.Horizontal customSize={22} />
        {/* message */}
        <Row
          alignItems='center'
          id={getBemId(blockId, 'altiro-logo')}
          justifyContent='center'
          tagName='article'
          width='100%'
        >
          <Column alignItems='center'>
            <Text
              fontSize={configValue.modalMessageSize}
              id={getBemId(blockId, 'welcome-text')}
              textAlign='center'
            >
              {formatTextWithLineBreaks(modalMessage)}
            </Text>
            {membershipLevel !== 'diamante' && (
              <Text
                fontSize={configValue.modalMessageBoldyTextSize}
                fontWeight={configValue.modalMessageBoldyTextWeight}
                textAlign='center'
                type='span'
              >
                ¡Más compras, más ahorras!
              </Text>
            )}
          </Column>
        </Row>
      </Column>
    </Container>
  )
}
