import { CSSProperties, useEffect, useMemo, useState } from 'react'
import dynamic from 'next/dynamic'
import { useRouter } from 'next/router'
import {
  Container,
  SomethingIsWrongModal,
  ToastProps
} from '@smu-chile/pkg-unimarc-components'
import {
  BigScreen,
  getGlobalStyle,
  SmallScreen
} from '@smu-chile/pkg-unimarc-components/helpers'
import { FooterLoader } from '@smu-chile/pkg-unimarc-components/stories/organisms/Footer'
import {
  cleanUrlParamsLogin,
  ContentfulResponse,
  Cookies,
  eventClick,
  getCookie,
  getCurrentDomain,
  getParamsInURL,
  IAlertMessage,
  IContentfulResponse,
  IPostValidateSC,
  IResponse,
  isValidArrayWithData,
  postRegenerateOrderForm,
  postValidateSc,
  sleep,
  trigger,
  updateTagOrderForm,
  useAbandonedCart,
  useClearOrderForm,
  useContentful,
  useEvents,
  useInitialDatalayer,
  useNewCartUnimarc,
  useOnScreen,
  useOrderForm,
  useQueryClient,
  useSession,
  useSessionState,
  useUpdateQuantityQuickly
} from '@smu-chile/pkg-unimarc-hooks'
import { ConfirmationModalProps } from 'components/MiniCart/ConfirmationModal'
import {
  DeliverySelectorTypeComponentProps,
  FooterProps,
  HeaderBrowseProps,
  ModalMembershipLevelQueryWrapper,
  ModalMembershipTypeWrapper,
  ModalRegisterClubWrapper,
  NoStockModalProps,
  StoreLocatorComponentProps,
  ValidateSlaWrapper
} from '..'
import {
  CONFIRM_ADDRESS_MODAL_IMAGE,
  SOMETHING_IS_WRONG_MODAL_IMAGE
} from 'shared/utils/constanst'
import { DataCart } from '@smu-chile/pkg-unimarc-hooks/shared/interfaces/INewCartUnimarc'
import { TooltipAddressChanged } from 'components/ModalConfirmChangeAddress/TooltipAddressChanged'
import { MembershipWrapper } from 'components/MembershipWrapper'
import { useQueryMembershipLevel } from './helpers'

const Toast = dynamic<ToastProps>(
  import('@smu-chile/pkg-unimarc-components').then((mod) => {
    return mod.Toast
  })
)
const ConfirmationModal = dynamic<ConfirmationModalProps>(
  import('components/MiniCart/ConfirmationModal').then((mod) => {
    return mod.ConfirmationModal
  })
)
const StoreLocatorComponent = dynamic<StoreLocatorComponentProps>(
  import('..').then((mod) => {
    return mod.StoreLocatorComponent
  })
)
const DeliverySelectorTypeComponent =
  dynamic<DeliverySelectorTypeComponentProps>(
    import('..').then((mod) => {
      return mod.DeliverySelectorTypeComponent
    })
  )
const Footer = dynamic<FooterProps>(
  import('..').then((mod) => {
    return mod.Footer
  }),
  { ssr: false, loading: FooterLoader }
)
const HeaderBrowse = dynamic<HeaderBrowseProps>(
  import('..').then((mod) => {
    return mod.HeaderBrowse
  }),
  { ssr: true }
)
const NoStockModal = dynamic<NoStockModalProps>(
  import('..').then((mod) => {
    return mod.NoStockModal
  })
)

export interface LayoutProps {
  children: React.ReactNode
  backgroundColor?: CSSProperties['backgroundColor']
  hideHeader?: boolean
  hideFooter?: boolean
  isMobile?: boolean
  showFooter?: boolean
  showHeader?: boolean
}

export const Layout = ({
  backgroundColor,
  children,
  hideHeader,
  hideFooter,
  isMobile
}: LayoutProps): React.ReactElement => {
  const router = useRouter()
  const domain = getCurrentDomain()
  const paramsApp = getParamsInURL('source')
  const showHeaderAndFooter = router?.query?.source !== 'APP'
  const { isLoggedIn } = useSession()
  const { handleMutate } = useUpdateQuantityQuickly()
  const queryClient = useQueryClient()
  const [isDownloadToastOpen, setIsDownloadToastOpen] = useSessionState(
    'isDownloadToastOpen',
    false
  )
  const [open, setOpen] = useState(false)
  const [openSmIsWrongModal, setOpenSmIsWrongModal] = useState(false)
  const [errorCodeResponse, setErrorCodeResponse] = useState('')
  const [openModalSla, setOpenModalSla] = useState(false)
  const [validateSlaResponse, setValidateSlaResponse] =
    useState<IResponse<IPostValidateSC>>()
  const background = backgroundColor ?? getGlobalStyle('--color-neutral-white')
  const { mutate } = useClearOrderForm()
  const { data, isLoading } = useOrderForm()
  const { data: newCart } = useNewCartUnimarc()
  const { elementRef, isIntersecting } = useOnScreen({})
  const [tooltipAddressWasChanged, setTooltipAddressWasChanged] =
    useState(false)
  // noStockModal
  const [failedData, setFailedData] = useState(null)
  // custom hooks to handle membership level query
  const {
    showModalMembershipLevelQuery,
    blockedBySecurity,
    showModalMembershipType,
    membershipType,
    showModalRegisterClub,
    isLoading: isLoadingMembershipQueryLevel,
    setShowModalMembershipLevelQuery,
    handleMembershipLevelQueryContinue,
    handleToggleMembershipLevelQuery,
    handleToggleMembershipType,
    handleToggleRegisterClub
  } = useQueryMembershipLevel()
  // trigger the abandoned cart flow
  useAbandonedCart()
  // send initial info to datalayer when user is logged in
  useInitialDatalayer({ isLoggedIn, sc: data?.data?.salesChannel })

  useEvents({
    eventType: 'addToCart',
    callBack: async ({ detail: { items } }) => {
      handleMutate(items)
    }
  })

  useEvents({
    eventType: 'clearCart',
    callBack: ({ detail: { show } }) => {
      setOpen(show)
    }
  })

  // Invalidate the query cache when the user refreshes the token
  // this is called by postRefreshToken function in pkg-unimarc-hooks
  useEvents({
    eventType: 'refreshToken',
    callBack: () => {
      queryClient.invalidateQueries()
    }
  })

  useEvents({
    eventType: 'somethingIsWrong',
    callBack: ({ detail: { show, errorCode } }) => {
      setErrorCodeResponse(errorCode)
      setOpenSmIsWrongModal(!!show)
    }
  })

  // Event to validate if the orderform generate error with some item
  // and show the noStockModal
  useEvents({
    eventType: 'loadingOrderForm',
    callBack: async ({ detail: { loading } }) => {
      if (!loading) {
        await sleep(1000)
        const getMutateData = queryClient
          .getMutationCache()
          .findAll({ mutationKey: 'mutateOrderForm' })
          .slice(-1)[0]

        const dataCartMutate = getMutateData?.state?.data as DataCart

        if (isValidArrayWithData(dataCartMutate?.removedProducts)) {
          setFailedData(dataCartMutate)
        }
      }
    }
  })

  const somethingIsWrongModal = useContentful({
    id_contentful: 'contentful-builtin-asset-content-type-modal-sm-is-wrong',
    options: {
      'sys.id': SOMETHING_IS_WRONG_MODAL_IMAGE[0]
    },
    type: 'assets'
  })

  const confirmAddressModal = useContentful({
    id_contentful:
      'contentful-builtin-asset-content-type-modal-confirm-address',
    options: {
      'sys.id': CONFIRM_ADDRESS_MODAL_IMAGE[0]
    },
    type: 'assets'
  })

  const confirmAddressModalImage =
    confirmAddressModal.data?.['items']?.[0]?.fields?.file?.url || ''

  const somethingIsWrongModalImage =
    somethingIsWrongModal.data?.['items']?.[0]?.fields?.file?.url || ''

  const handleCloseNoStockModal = () => {
    setFailedData(false)
    Cookies.set('addToCart', '[]', {
      domain,
      path: '/'
    })
  }

  const handleClose = (cleanCart = false) => {
    trigger({ eventType: 'clearCart', data: { show: false, cleanCart } })
  }

  const handleDownloadToastClose = () => {
    setIsDownloadToastOpen(false)
  }

  const handleOnBlurToastButton = () => {
    eventClick({
      event: 'interacciones_referencias_app',
      eventAction: 'clic',
      eventCategory: 'modal_descarga',
      eventLabel: 'descargar'
    })
  }

  const handleCloseTooltip = () => {
    setTooltipAddressWasChanged(false)
    Cookies.remove('showAddressChangedTooltip', {
      domain,
      path: '/'
    })
  }

  const downloadToastResponse = useContentful({
    id_contentful: 'app_download_toast',
    options: {
      content_type: 'alertMessage',
      'fields.abled': true,
      'fields.idFormato': 1,
      'fields.platform': 'Web Unimarc eComm'
    }
  })

  const downloadToastContent = useMemo(() => {
    if (!downloadToastResponse.data) return null
    return new ContentfulResponse(
      downloadToastResponse.data as IContentfulResponse
    )
      .populateEntries()
      .getResponse().items?.[0] as unknown as IAlertMessage
  }, [downloadToastResponse.data])

  const cleanCartMinicart = () => {
    handleClose(true)
    return mutate()
  }

  const handleOnCloseSmIsWrong = () => {
    setOpenSmIsWrongModal(false)
    setOpenModalSla(false)
    trigger({
      eventType: 'storeLocator',
      data: { show: true, sameAddressOnClose: true }
    })
  }

  useEffect(() => {
    cleanUrlParamsLogin({
      router,
      ignoreItems: ['q', 'page', 'source', 'GoSSO', 'GotoMembership']
    })
    const showModalMembershipLevelQueryByTime = async () => {
      await sleep(1500)
      // show modal balance inquiry membership
      // to the client consultation flow
      if (router?.query?.membresia) {
        setShowModalMembershipLevelQuery(true)
      }
    }
    showModalMembershipLevelQueryByTime()
  }, [])

  useEffect(() => {
    // With this configuration we can to update the cart when
    // the user change between pages and he are logged and doesn't do the request
    const handleUpdateCart = () => {
      if (!isLoggedIn || failedData) return
      const recoverAddToCartPending = JSON.parse(
        Cookies.get('addToCart') || '[]'
      )
      if (
        recoverAddToCartPending.length > 0 &&
        queryClient.isMutating({
          mutationKey: 'mutateOrderForm',
          fetching: true
        }) == 0 &&
        !queryClient.isFetching('orderForm')
      ) {
        handleMutate(recoverAddToCartPending)
      }
    }

    handleUpdateCart()
  }, [isLoggedIn, queryClient.isFetching('orderForm'), failedData])

  // The role of this function is to search the "addTocart" cookie and
  // send his data to the backend for add of the orderForm
  // and the same way validate if the use mobile and force reload when a change to a page with arrow back
  useEffect(() => {
    const validaReload = (event: { persisted: boolean }): void => {
      const historyTraversal =
        event.persisted ||
        (typeof window.performance != 'undefined' &&
          performance?.getEntriesByType('navigation')?.[0]?.['type'] ===
            'back_forward')

      if (historyTraversal) {
        window.location.reload()
      }
    }

    window.addEventListener('pageshow', validaReload)
    return () => {
      window.removeEventListener('pageshow', validaReload)
    }
  }, [isLoggedIn, queryClient.isFetching('orderForm')])

  //validate if user is logged in & delete param from url
  useEffect(() => {
    const url = new URL(window.location.href)
    const notLoggedParam = 'login'

    if (isLoggedIn && url.searchParams.has(notLoggedParam)) {
      url.searchParams.delete(notLoggedParam)
      router.push(url.toString())
    }
  }, [isLoggedIn])

  // validate if the orderform has failed or info properties with data
  // and show the noStockModal
  useEffect(() => {
    if (isValidArrayWithData(newCart?.removedProducts)) {
      setFailedData(newCart)
    }
  }, [newCart])

  /**
   * This useEffect is responsible for handling the orderform based on the data, client status, and fetching state.
   * It triggers an asynchronous function to process the orderform and update it if necessary, ensuring that
   * certain conditions are met (e.g., user is logged in, orderForm data exists and is not being currently fetched).
   * If the conditions are not met, it exits early to prevent unnecessary operations.
   * It also processes and maps the items from the orderForm, and regenerates the orderForm if an address is present.
   * In case of successful regeneration, it resets the query for the 'orderForm' to reflect the new state.
   */
  useEffect(() => {
    const handleOrderForm = async () => {
      // Exit early if user is not logged in
      if (!isLoggedIn) return

      // Exit early if 'orderForm' is being fetched
      if (queryClient.isFetching('orderForm')) return

      // Extract orderForm data and client data
      const orderFormData = data?.data
      const clientData = data?.data?.clientProfileData

      // Exit early if orderForm data is not present or orderFormId is missing
      if (!orderFormData?.orderFormId) return

      // Exit early if client email is present
      if (clientData?.email) return

      // Retrieve selected address from cookies or default to an empty object if none found
      const address = JSON.parse(getCookie('selectedAddresses') || '{}')

      // Map orderForm items to a simplified structure or default to an empty array if no items present
      const items =
        (orderFormData?.items?.length > 0 &&
          orderFormData.items.map(({ id, quantity, seller }) => {
            return {
              id,
              quantity,
              seller
            }
          })) ||
        []

      // trigger StoreLocator and exit early if no address is present
      if (!address?.addressType) {
        trigger({
          eventType: 'storeLocator',
          data: { show: true, dontAllowCloseSL: true }
        })
        return
      }

      // Attempt to regenerate the orderForm with the given data
      const responseRegenerateOrderForm = await postRegenerateOrderForm({
        data: {
          items,
          shippingData: {
            address,
            logisticsInfo: []
          }
        }
      })

      // Exit early if the regeneration response does not contain a URL
      if (!responseRegenerateOrderForm?.url) return

      // Reset the 'orderForm' query to reflect the new state
      queryClient.resetQueries('orderForm')
    }

    // Trigger the handleOrderForm function
    handleOrderForm()
  }, [
    data?.data?.clientProfileData,
    queryClient.isFetching('orderForm'),
    isLoggedIn
  ])

  // Validate sales channel for SLA NULL flow
  useEffect(() => {
    const validateSC = async () => {
      if (isLoading) return
      // use BE service to validate if user have a different sales channel in another session
      const validateSla = await postValidateSc({
        geoCoordinates: data?.data?.selectedAddresses?.geoCoordinates
      })
      setValidateSlaResponse(validateSla.data)
      setOpenModalSla(validateSla?.status === 409)
    }
    if (data?.data?.selectedAddresses?.geoCoordinates?.length > 0) {
      validateSC()
    }
    return () => {
      setOpenModalSla(false)
    }
  }, [data?.data?.selectedAddresses, isLoading])

  useEffect(() => {
    if (isLoading) return

    updateTagOrderForm({ orderForm: data?.data || { noSession: true } })
  }, [data, isLoading])

  useEffect(() => {
    const showTooltip = JSON.parse(
      Cookies.get('showAddressChangedTooltip') || 'false'
    )
    setTooltipAddressWasChanged(showTooltip)
  }, [])

  return (
    <Container
      backgroundColor={background}
      isWrap
    >
      <MembershipWrapper />
      {showModalRegisterClub && (
        <ModalRegisterClubWrapper handleToggle={handleToggleRegisterClub} />
      )}

      {showModalMembershipLevelQuery && (
        <ModalMembershipLevelQueryWrapper
          handleContinue={handleMembershipLevelQueryContinue}
          handleToggle={handleToggleMembershipLevelQuery}
          isBlockedBySecurity={blockedBySecurity}
          isLoading={isLoadingMembershipQueryLevel}
        />
      )}
      {showModalMembershipType && (
        <ModalMembershipTypeWrapper
          handleToggle={handleToggleMembershipType}
          membershipLevel={membershipType}
        />
      )}
      {/* Header */}
      {openSmIsWrongModal && (
        <SomethingIsWrongModal
          errorCode={errorCodeResponse}
          isMobile={isMobile}
          isOpen={openSmIsWrongModal}
          onClose={handleOnCloseSmIsWrong}
          somethingIsWrongImage={somethingIsWrongModalImage}
        />
      )}
      {!hideHeader && showHeaderAndFooter && (
        <HeaderBrowse
          isDownloadToastOpen={isDownloadToastOpen}
          isMobile={isMobile}
        >
          <ValidateSlaWrapper
            openModalSla={openModalSla}
            orderFormData={data?.data}
            setOpenModalSla={setOpenModalSla}
            validateSlaResponse={validateSlaResponse?.data}
          />
          {/* Toast */}
          {isDownloadToastOpen && downloadToastContent && !paramsApp && (
            <SmallScreen>
              <Toast
                button={downloadToastContent.button}
                fixedPosition={downloadToastContent.fixedPosition}
                hideOnScroll={downloadToastContent.hideOnScroll}
                iconUrl={downloadToastContent.icon?.fields?.file?.url}
                isFixed={downloadToastContent.isFixed}
                message={downloadToastContent.message}
                onBlurButton={handleOnBlurToastButton}
                onClose={handleDownloadToastClose}
                showClose={downloadToastContent.showClose}
                title={downloadToastContent.title}
              />
            </SmallScreen>
          )}
        </HeaderBrowse>
      )}
      {tooltipAddressWasChanged && (
        <TooltipAddressChanged onClose={handleCloseTooltip} />
      )}
      {/* Body of component */}
      {children}
      {/* Minicart and confirmation modal */}
      <BigScreen>
        <>
          {open && (
            <ConfirmationModal
              handleClose={handleClose}
              handleMutate={cleanCartMinicart}
            />
          )}
        </>
      </BigScreen>
      {/* Storelocator and delivery selector */}
      {isLoggedIn && (
        <>
          <StoreLocatorComponent
            confirmAddressModalImage={confirmAddressModalImage}
          />
          <DeliverySelectorTypeComponent
            confirmAddressModalImage={confirmAddressModalImage}
          />
        </>
      )}
      {failedData && (
        <NoStockModal
          data={failedData}
          onClick={handleCloseNoStockModal}
          orderFormData={data}
        />
      )}
      <div ref={elementRef} />
      {/* Footer */}
      {!hideFooter && showHeaderAndFooter && (
        <Footer isVisible={isIntersecting} />
      )}
    </Container>
  )
}
