import { useMemo } from 'react'
import Link from 'next/link'
import { useRouter } from 'next/router'
import {
  Backbone,
  Pagination,
  PaginationProps,
  Row
} from '@smu-chile/pkg-unimarc-components'
import { clickPlp } from 'shared/utils'
export interface CustomPaginationProps extends Partial<PaginationProps> {
  currentPage: number
  isLoadingPage: boolean
  isVisableButtonLeft: boolean
  isVisableButtonRight: boolean
  isVisableButtons: boolean
  items: (string | number)[]
  pageQuantity: number
  urlParams?: string
}

export const CustomPagination = ({
  isLoadingPage,
  ...propsNavigation
}: CustomPaginationProps) => {
  const router = useRouter()
  const path = router?.asPath?.replace(/\?.*/, '')
  // in ...propsNavigation we're drilldown the urlParams from navigationProps obj
  // that comes from BodyPageProducts
  // so, if we dont send urlParams property we're going to drilldown this urlParams
  // to Pagination component
  const urlParams = useMemo(() => {
    const ignoreKeys = ['page', 'categories', 'login', 'offers']
    const entriesData = Object.entries(router?.query || {})
    return (
      entriesData?.length > 0 &&
      entriesData
        .map(([key, value]) => {
          if (ignoreKeys.includes(key)) return null
          return `${key}=${value}`
        })
        .filter((item) => {
          return item !== null
        })
        .join('&')
    )
  }, [JSON.stringify(router?.query)])

  if (isLoadingPage) {
    return (
      <Row customWidth='25'>
        <Backbone
          borderRadius='20px'
          height={30}
        />
      </Row>
    )
  }

  const handleNextPage = () => {
    clickPlp('Siguiente', 'paginacion')
  }
  const handlePrevPage = () => {
    clickPlp('Anterior', 'paginacion')
  }
  const handleOnClick = (number: number) => {
    clickPlp(number, 'paginacion')
  }

  return (
    <Pagination
      handleNextPage={handleNextPage}
      handleOnClick={handleOnClick}
      handlePrevPage={handlePrevPage}
      linkWrapper={Link}
      path={path}
      urlParams={urlParams}
      {...propsNavigation}
    />
  )
}
