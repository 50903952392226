import dynamic from 'next/dynamic'
import { useEffect, useState } from 'react'
import { useRouter } from 'next/router'
import {
  datalayerSimpleEvent,
  generateBreadcrumbJoin,
  IContentfulResponse,
  isValidArrayWithData,
  useAndesML,
  useCatalogFacets,
  useCategories,
  useContentful,
  usePagination,
  useProductsSearch
} from '@smu-chile/pkg-unimarc-hooks'
import {
  changeUrlCategories,
  contentBannerLegal,
  dataForBreadcrumbs,
  getInitialFilters,
  getQueryKeyToRequest,
  getSubCategoriesFilters,
  matchOptionKey,
  isContinuosFetching,
  paginationCal,
  promotionsOfShelves
} from 'shared/helpers'
import {
  useFilterCategories,
  useFilter,
  useOrder,
  useFacets
} from 'shared/utils/hooks'
import { getBannerLegal } from './helpers/getBannerLegal'
import { SearchBodyProduct, SearchBodyProductProps } from './SearchBodyProduct'
import { REACT_QUERY_GENERAL } from '../../shared/constants/reactQuery'
import { ErrorPageV2Props } from 'components/ErrorPageV2'
import { CAMPAING_APP_CHIPS_UNIMARC } from 'shared/utils/constanst'

const ErrorPage = dynamic<ErrorPageV2Props>(
  import('..').then((mod) => {
    return mod.ErrorPageV2
  })
)
interface ProductSearchPagePros {
  bannerLegal?: IContentfulResponse
  isMobile?: boolean
  page: number
  search: string
  categorySlug?: string
}

const initialLimit = 49

export const ProductSearchPage = ({
  bannerLegal,
  isMobile,
  search
}: ProductSearchPagePros) => {
  const router = useRouter()
  const {
    applyFilters,
    compareAndSetInitialData,
    currentSelectedFilters,
    filterData
  } = useFilter(router) || {}

  const {
    onClickFilter,
    onClickFilterModal,
    onClickRadioOrderByOption,
    showModal,
    onApplyFilterModal,
    selectedRadio,
    disabledButton
  } = useOrder(router)

  const { showFacets, handleOpenFacetModal } = useFacets()

  const { handleClickCategoryPill, place, currentCategory } =
    useFilterCategories(router)

  const page = parseInt(router?.query?.page as string) || 1
  const { offset, end: limit } = paginationCal({ page, limit: 50 })
  const nextLimit = offset + initialLimit
  const [offSet, setOffSet] = useState(offset)
  const [countLimit, setCountLimit] = useState(nextLimit)
  const [products, setProducts] = useState([])
  const [resources, setResources] = useState(Infinity)
  const { breadcrumbData, title } = dataForBreadcrumbs({ router, search })
  const dataBreadCrumb = changeUrlCategories(breadcrumbData)
  const orderByFilterName = matchOptionKey(
    router?.query?.orderBy?.toString() ?? ''
  )
  // Get all promotions available in contenful
  const resultPromotions = useContentful({
    options: {
      content_type: process?.env?.NEXT_PUBLIC_CAMPAIGN_TYPE,
      'sys.id[in]': CAMPAING_APP_CHIPS_UNIMARC[0]
    },
    reactQuery: REACT_QUERY_GENERAL
  })
  // Clean the object of promtions
  const dataPromotions = promotionsOfShelves({ data: resultPromotions?.data })

  const dataBannerLegal = getBannerLegal({ bannerLegal, search })

  const {
    bannerImages,
    contentLegalParagraph,
    contentLegalTitle,
    searchBannerData
  } = contentBannerLegal(dataBannerLegal)

  const urlLinkOffer = dataBannerLegal?.['items']?.[0]?.fields?.urlRedirect
  const continuosFetching: boolean = isContinuosFetching({
    countLimit,
    resources,
    limit
  })

  const reactQueryBySearch = {
    ...REACT_QUERY_GENERAL
  }

  const initialFilters = getInitialFilters(router?.query)

  const keysToQuery = getQueryKeyToRequest([
    ...initialFilters,
    {
      value: {
        ...currentSelectedFilters,
        ...applyFilters
      }
    },
    { value: currentCategory },
    { value: search.trim() },
    { value: offset.toString() },
    { value: countLimit.toString() },
    { value: orderByFilterName }
  ])
  const productsBySearch = useProductsSearch({
    ...currentSelectedFilters,
    ...applyFilters,
    categories: currentCategory,
    from: offSet.toString(),
    searching: search.trim(),
    to: countLimit.toString(),
    orderBy: orderByFilterName,
    userTriggered: true,
    reactQuery: {
      ...reactQueryBySearch,
      queryKey: keysToQuery
    }
  })
  const bannerAndesMl = useAndesML({
    deviceType: isMobile ? 'MOBILE' : 'DESKTOP',
    pageType: 'SRP',
    requestPage: `${process.env.NEXT_PUBLIC_HOMEURL}${router.asPath}`,
    searchText: search.trim(),
    slot: 'TOP BANNERS',
    reactQuery: {
      refetchOnWindowFocus: false
    }
  })

  const responseFacetsData = useCatalogFacets({
    ...currentSelectedFilters,
    ...applyFilters,
    categories: currentCategory,
    searching: search.trim(),
    reactQuery: {
      ...reactQueryBySearch,
      queryKey: keysToQuery
    }
  })

  const totalQuantity = parseInt(productsBySearch?.data?.resource) || 0

  const isLoadingPage = productsBySearch.isLoading || resultPromotions.isLoading

  const facetsFilterV2 = responseFacetsData?.data || {}

  const propsNavigation = usePagination({
    totalQuantity,
    itemPerPage: 50,
    defaultPage: page,
    handleQuantity: () => {
      const newCountLimit = nextLimit > limit ? limit : nextLimit
      setOffSet(offset)
      setCountLimit(newCountLimit)
    }
  })

  const isLoadingFacets =
    responseFacetsData?.isLoading || responseFacetsData?.isFetching

  const handleFetchProducts = () => {
    if (!productsBySearch.isLoading && continuosFetching) {
      setOffSet(countLimit + 1)
      setCountLimit((prev) => {
        const defaultLimit = limit > resources ? resources : limit
        const nextLimit = prev + initialLimit + 1
        return nextLimit > limit || nextLimit > resources
          ? defaultLimit
          : nextLimit
      })
    }
  }
  // get all categories menu like with getCategories service
  const resultAisles = useCategories({
    level: 2,
    reactQuery: REACT_QUERY_GENERAL
  })
  const subCategories = getSubCategoriesFilters({
    facetsFilterV2
  })
  const dataOfBodyPageProducts: SearchBodyProductProps = {
    bannerImages,
    bannerAndesMl: bannerAndesMl?.data?.[0] || searchBannerData,
    breadcrumbData: dataBreadCrumb,
    contentLegalParagraph,
    contentLegalTitle,
    dataPromotions,
    facetsFilterV2,
    filterData,
    imageAndes: bannerAndesMl?.data?.[0]?.imageUrl,
    isLoadingBannerAndes: bannerAndesMl.isLoading,
    isLoadingPage,
    isLoadingFacets,
    isMobile,
    place,
    products,
    propsNavigation,
    searchBannerData: bannerAndesMl?.data?.[0] || searchBannerData,
    subCategories,
    title,
    totalQuantity,
    urlLinkOffer: bannerAndesMl?.data?.[0]?.['redirectUrl'] || urlLinkOffer,
    setCountLimit: handleFetchProducts,
    onClickFilter,
    onClickCategoryPill: handleClickCategoryPill,
    onClickFilterModal,
    onClickRadioOrderByOption,
    showModal,
    onApplyFilterModal,
    resultAisles: resultAisles,
    selectedRadio,
    disabledButton,
    handleOpenFacetModal,
    hasFacets: filterData.hasFilter,
    showFacets,
    query: search
  }

  useEffect(() => {
    compareAndSetInitialData(facetsFilterV2)
  }, [facetsFilterV2])

  useEffect(() => {
    propsNavigation.setPage(page)
  }, [page])

  useEffect(() => {
    generateBreadcrumbJoin(dataBreadCrumb)
  }, [dataBreadCrumb])

  useEffect(() => {
    !isLoadingPage &&
      datalayerSimpleEvent({
        event: 'search',
        eventAction: 'clic',
        eventCategory: 'busqueda',
        eventLabel: 'lupa',
        search_term: search,
        search_type: 'lupa',
        search_count: totalQuantity
      })
  }, [isLoadingPage])

  // Update products per page, there logic to implement the fragment
  // request and show the products in two or more steps
  useEffect(() => {
    const {
      availableProducts = [],
      notAvailableProducts = [],
      resource: count
    } = productsBySearch?.data || {}
    const newProducts = [...availableProducts, ...notAvailableProducts]
    if (count && parseInt(count) !== resources) setResources(parseInt(count))
    setProducts((prev) => {
      const prevData = continuosFetching ? prev : []
      return isLoadingPage && continuosFetching
        ? []
        : [...prevData, ...newProducts]
    })
  }, [productsBySearch.data, isLoadingPage, countLimit])

  // when service in client-side response with error property
  if (
    !isLoadingPage &&
    (productsBySearch?.isError ||
      (productsBySearch?.error && !productsBySearch?.data?.resource))
  ) {
    return <ErrorPage errorType='400' />
  }

  // when service in client-side response a 404 or no data
  if (
    !isLoadingPage &&
    productsBySearch?.error &&
    !isValidArrayWithData(productsBySearch?.data?.availableProducts)
  ) {
    return <ErrorPage errorType='404' />
  }

  return <SearchBodyProduct {...dataOfBodyPageProducts} />
}
