import {
  DesktopModalWrapper,
  MobileModalWrapper
} from '@smu-chile/pkg-unimarc-components'
import {
  BigScreen,
  getGlobalStyle,
  SmallScreen
} from '@smu-chile/pkg-unimarc-components/helpers'
import { redirectTo } from '@smu-chile/pkg-unimarc-hooks'
import { useRouter } from 'next/router'
import { cleanMembershipQueryParam } from 'shared/helpers'
import { ModalMembershipTypeContent } from './components/ModalMembershipTypeContent'

interface ModalMembershipTypeWrapperProps {
  membershipLevel?: string
  handleToggle: () => void
}

export const MODAL_MEMBERSHIP_TYPE_WRAPPER_ID = {
  MOBILE: 'modalMembershipTypeWrapperMobile',
  DESKTOP: 'modalMembershipTypeWrapperDesktop'
}
export const ModalMembershipTypeWrapper = ({
  membershipLevel = '4',
  handleToggle
}: ModalMembershipTypeWrapperProps): React.ReactElement => {
  const router = useRouter()

  const handleClose = () => {
    cleanMembershipQueryParam(router, handleToggle)
  }

  const handleRedirect = () => {
    redirectTo(process.env.NEXT_PUBLIC_HOMEURL)
  }
  return (
    <>
      <BigScreen>
        <DesktopModalWrapper
          blockId={MODAL_MEMBERSHIP_TYPE_WRAPPER_ID.DESKTOP}
          bodyChildrenProps={{
            padding: '0 49px'
          }}
          borderEdge='none'
          footerChildrenProps={{
            buttonDefaultProps: {
              onClick: handleRedirect,
              fontWeight: getGlobalStyle('--font-weight-medium'),
              label: 'Ir a comprar'
            },
            maxWidth: '400px',
            boxShadow: 'none',
            default: true
          }}
          hasfooter={true}
          headerCloseIcon={{
            customSize: 13,
            default: true
          }}
          headerTitleProps={{
            fontWeight: 'medium'
          }}
          modalConfigsProps={{
            maxWidth: '800px',
            isWindowBlocked: true,
            maxHeight: '440px',
            minHeight: '450px',
            isOpen: true,
            toggle: handleClose,
            toggleOutside: handleClose
          }}
          styleProps={{
            customHeightModalContent: '100%'
          }}
        >
          <ModalMembershipTypeContent membershipLevel={membershipLevel} />
        </DesktopModalWrapper>
      </BigScreen>

      <SmallScreen>
        <MobileModalWrapper
          blockId={MODAL_MEMBERSHIP_TYPE_WRAPPER_ID.MOBILE}
          body={
            <ModalMembershipTypeContent membershipLevel={membershipLevel} />
          }
          buttonConfigsProps={{
            onClick: handleRedirect,
            fontWeight: getGlobalStyle('--font-weight-medium'),
            label: 'Ir a comprar',
            isLoading: false
          }}
          dragProps={{
            draggButton: false
          }}
          hasFooter={true}
          headerCloseIconProps={{
            customSize: 13
          }}
          headerProps={{
            padding: '3px 16px'
          }}
          hideHeaderDivider={false}
          iconColor={getGlobalStyle('--color-base-black')}
          iconSize={16}
          isEnableButton
          isOpen={true}
          modalConfigsProps={{
            openAnimation: true,
            fullScreenSticky: true,
            hiddenX: true,
            isAutoHeight: true,
            isOpen: true,
            isWindowBlocked: true,
            marginFullScreen: '1vh 0 0',
            stickyBottom: true,
            toggle: handleClose,
            toggleOutside: handleClose
          }}
          onClose={handleToggle}
          styleProps={{
            padding: '0',
            height: '100%',
            borderRadius: `${getGlobalStyle(
              '--border-radius-md'
            )} ${getGlobalStyle('--border-radius-md')} 0 0`
          }}
        />
      </SmallScreen>
    </>
  )
}
