import { useEffect } from 'react'
import dynamic from 'next/dynamic'
import {
  BigScreen,
  getGlobalStyle,
  SmallScreen
} from '@smu-chile/pkg-unimarc-components/helpers'
import {
  Column,
  Container,
  Spacer,
  SelectByOptions,
  FacetFilters,
  Button,
  MobileModalWrapper
} from '@smu-chile/pkg-unimarc-components'
import {
  generateBreadcrumbJoin,
  isValidArrayWithData,
  useCatalogFacets,
  useCategories,
  useContentful,
  usePagination,
  useProductsSearch
} from '@smu-chile/pkg-unimarc-hooks'
import {
  contentBannerLegal,
  getInitialFilters,
  getQueryKeyToRequest,
  getSubCategoriesFilters,
  matchOptionKey,
  paginationCal,
  promotionsOfShelves,
  orderByOptions
} from '../../shared/helpers'
import {
  useFilterCategories,
  useFilter,
  useOrder,
  useFacets
} from 'shared/utils/hooks'
import { OffersProps } from 'pages/ofertas/[...offers]'
import {
  BodyPageProducts,
  BodyPageProductsProps,
  ErrorPageV2Props,
  LegalTerms,
  MonetiseBannerProps
} from 'components'
import { useRouter } from 'next/router'
import { REACT_QUERY_GENERAL } from 'shared/constants/reactQuery'
import { CAMPAING_APP_CHIPS_UNIMARC } from 'shared/utils/constanst'

const ErrorPage = dynamic<ErrorPageV2Props>(
  import('..').then((mod) => {
    return mod.ErrorPageV2
  })
)

const MonetiseBanner = dynamic<MonetiseBannerProps>(
  import('components').then((mod) => {
    return mod.MonetiseBanner
  })
)

const OrderByRadioButtonList = dynamic<SelectByOptions>(
  import('@smu-chile/pkg-unimarc-components').then((mod) => {
    return mod.OrderByRadioButtonList
  })
)

const Main = ({ children }: { children: React.ReactNode }) => {
  return (
    <Container
      alignItems='start'
      backgroundColor={getGlobalStyle('--color-background-white')}
      justifyContent='center'
      tagName='main'
    >
      {children}
    </Container>
  )
}

export const ProductOfferPage = ({
  bannerLegal,
  clusterId,
  clusterName,
  isMobile,
  reference,
  title
}: OffersProps) => {
  const router = useRouter()
  const orderByFilterName = matchOptionKey(
    router?.query?.orderBy?.toString() ?? ''
  )
  const {
    applyFilters,
    compareAndSetInitialData,
    currentSelectedFilters,
    filterData
  } = useFilter(router) || {}

  const { currentCategory, handleClickCategoryPill, place } =
    useFilterCategories(router) || {}

  const page = parseInt(router?.query?.page as string) || 1
  //call data
  const chipsData = useContentful({
    options: {
      content_type: process?.env?.NEXT_PUBLIC_CAMPAIGN_TYPE,
      'sys.id[in]': CAMPAING_APP_CHIPS_UNIMARC[0]
    },
    reactQuery: REACT_QUERY_GENERAL
  })
  const { offset, end } = paginationCal({ page, limit: 50 })

  const initialFilters = getInitialFilters(router?.query)

  const reactQueryByOffer = {
    ...REACT_QUERY_GENERAL
  }
  const keysToQuery = getQueryKeyToRequest([
    ...initialFilters,
    {
      value: {
        ...currentSelectedFilters,
        ...applyFilters
      }
    },
    { value: currentCategory },
    { value: clusterId },
    { value: clusterName },
    { value: offset.toString() },
    { value: end.toString() },
    { value: orderByFilterName }
  ])

  const { data: offerProductsData, ...offerProductProps } = useProductsSearch({
    ...currentSelectedFilters,
    ...applyFilters,
    categories: currentCategory,
    clusterId: clusterId,
    clusterNames: clusterName,
    from: offset.toString(),
    to: end.toString(),
    orderBy: orderByFilterName,
    reactQuery: {
      ...reactQueryByOffer,
      queryKey: keysToQuery
    }
  })

  const responseFacetsData = useCatalogFacets({
    ...currentSelectedFilters,
    ...applyFilters,
    categories: currentCategory,
    clusterId: clusterId,
    clusterNames: clusterName,
    reactQuery: {
      ...REACT_QUERY_GENERAL,
      queryKey: keysToQuery
    }
  })

  const dataBreadCrumb = [
    {
      label: 'Inicio',
      url: '/',
      oneClick: () => {
        return null
      }
    },
    { label: `“${title}”`, url: '#' }
  ]
  // Get all chipsData available in contenful
  const { bannerImages, contentLegalParagraph, contentLegalTitle } =
    contentBannerLegal(bannerLegal)
  const urlLinkOffer = bannerLegal?.items?.[0]?.fields?.urlRedirect?.toString()
  const data = bannerLegal?.items?.[0]?.fields

  // Clean the object of promtions
  const dataPromotions = promotionsOfShelves({ data: chipsData?.data })
  const products = [
    ...(offerProductsData?.availableProducts || []),
    ...(offerProductsData?.notAvailableProducts || [])
  ]

  const totalQuantity = parseInt(offerProductsData?.resource) || 0
  const isLoadingPage =
    offerProductProps.isLoading || offerProductProps.isFetching
  const isLoadingFacets =
    responseFacetsData?.isLoading || responseFacetsData?.isFetching

  const propsNavigation = usePagination({
    totalQuantity,
    itemPerPage: 50,
    defaultPage: page
  })

  const facetsFilterV2 = responseFacetsData?.data || {}
  const subCategories = getSubCategoriesFilters({
    facetsFilterV2
  })

  const {
    onClickFilter,
    onClickFilterModal,
    onClickRadioOrderByOption,
    showModal,
    onApplyFilterModal,
    selectedRadio,
    disabledButton
  } = useOrder(router)

  const { showFacets, handleOpenFacetModal } = useFacets()
  // get all categories menu like with getCategories service
  const resultAisles = useCategories({
    level: 2,
    reactQuery: REACT_QUERY_GENERAL
  })
  const dataOfBodyPageProducts: BodyPageProductsProps = {
    bannerAndesMl: data,
    bannerImages,
    breadcrumbData: dataBreadCrumb,
    clusterName,
    dataPromotions,
    filterData,
    facetsFilterV2: responseFacetsData?.data,
    isLoadingFacets,
    isLoadingPage,
    isMobile,
    place,
    products,
    propsNavigation,
    reference,
    subCategories,
    title,
    totalQuantity,
    onClickFilter,
    onClickCategoryPill: handleClickCategoryPill,
    onClickFilterModal,
    onClickRadioOrderByOption,
    selectedRadio,
    handleOpenFacetModal,
    hasFacets: filterData.hasFilter,
    resultAisles: resultAisles,
    urlLink: urlLinkOffer
  }

  useEffect(() => {
    compareAndSetInitialData(facetsFilterV2)
  }, [facetsFilterV2])

  useEffect(() => {
    propsNavigation.setPage(page)
  }, [page])

  useEffect(() => {
    generateBreadcrumbJoin(dataBreadCrumb)
  }, [dataBreadCrumb])

  // when service in client-side response with error property
  if (
    !isLoadingPage &&
    (offerProductProps?.isError ||
      (offerProductsData?.error && !offerProductsData?.resource))
  ) {
    return <ErrorPage errorType='400' />
  }

  // when service in client-side response a 404 or no data
  if (
    !isLoadingPage &&
    offerProductsData?.error &&
    !isValidArrayWithData(offerProductsData?.availableProducts)
  ) {
    return <ErrorPage errorType='404' />
  }

  return (
    <Main>
      <BigScreen>
        <Column alignItems='center'>
          {bannerImages && (
            <MonetiseBanner
              data={data}
              images={bannerImages}
              key={clusterId || clusterName}
              padding='32px 0 0 0'
              urlLink={urlLinkOffer}
            />
          )}
          {!bannerImages && <Spacer.Horizontal size={24} />}
          <Container
            backgroundColor={getGlobalStyle('--color-background-white')}
            customWidth='90'
            isWrap
            maxWidth={getGlobalStyle('--width-max-desktop')}
            minHeight='90vh'
            padding='36px'
          >
            <Column alignItems='center'>
              <BodyPageProducts {...dataOfBodyPageProducts} />
            </Column>
            <LegalTerms
              paragraph={contentLegalParagraph}
              title={contentLegalTitle}
            />
          </Container>
        </Column>
      </BigScreen>
      <SmallScreen>
        <Column backgroundColor={getGlobalStyle('--color-base-white')}>
          {showModal && (
            <MobileModalWrapper
              blockId='orderBy'
              body={
                <OrderByRadioButtonList
                  onClick={onClickRadioOrderByOption}
                  options={orderByOptions}
                  selected={selectedRadio}
                />
              }
              headerTitle='Ordena tus productos'
              iconSize={20}
              isEnableButton={!disabledButton}
              modalConfigsProps={{
                isOpen: showModal,
                toggle: onClickFilterModal,
                toggleOutside: onClickFilterModal
              }}
              onApply={onApplyFilterModal}
              onClose={onClickFilterModal}
            />
          )}
          {showFacets && (
            <MobileModalWrapper
              blockId='facets'
              body={
                <FacetFilters
                  {...filterData}
                  isMobile={isMobile}
                />
              }
              headerLeftElement={
                filterData.hasFilter && (
                  <Column
                    flexGrow={0}
                    width='auto'
                  >
                    <Button
                      color={getGlobalStyle('--color-primary-light-red')}
                      fontSize='12px'
                      fontWeight='600'
                      height='initial'
                      label='Limpiar'
                      minWidth='auto'
                      onClick={filterData.handleClearOnClick}
                      type='plain'
                    />
                  </Column>
                )
              }
              headerTitle='Selecciona tus filtros'
              iconSize={20}
              isEnableButton={filterData.enableButton}
              modalConfigsProps={{
                isOpen: showFacets,
                toggle: handleOpenFacetModal,
                toggleOutside: handleOpenFacetModal
              }}
              onApply={() => {
                return filterData.handleApplyOnClick(handleOpenFacetModal)
              }}
              onClose={handleOpenFacetModal}
            />
          )}
          <BodyPageProducts {...dataOfBodyPageProducts} />
          <LegalTerms
            paragraph={contentLegalParagraph}
            title={contentLegalTitle}
          />
        </Column>
      </SmallScreen>
    </Main>
  )
}
